<template>
  <div class="get-prize">
    <div class="login" v-if="!openid">
      <div class="logo-wrap">
        <div class="logo">
          <img src="../assets/logo.png">
        </div>
        <h2 class="name">来客岛</h2>
      </div>
      <div class="login-btn">
        <van-button type="info" size="large" round @click="login">抖音授权登录</van-button>
        <!-- <div class="agree">已阅读并同意《用户协议》</div> -->
      </div>
    </div>
    <div class="draw-info" v-if="drawInfo">
      <div class="user">
        <img :src="drawInfo.user_avatar">
        <div class="name">{{ drawInfo.user_nickname }}</div>
      </div>
      <div class="ok">恭喜您抽中【{{ drawInfo.prize_name }}】</div>
      <div class="info">{{ drawInfo.draw_help }}</div>

      <van-form @submit="onSubmit" v-if="!drawInfo.draw_qrcode">
        <van-field v-model="user.name" name="联系人" label="联系人" placeholder="联系人"
          :rules="[{ required: true, message: '请填写联系人姓名' }]" />
        <van-field v-model="user.mobile" name="联系电话" label="联系电话" placeholder="联系电话"
          :rules="[{ required: true, message: '请填写联系电话' }]" />

        <van-field v-model="user.addr" type="textarea" name="邮寄地址" label="邮寄地址" placeholder="邮寄地址"
          :rules="[{ required: false, message: '请填写地址' }]" />
        <div style="margin: 16px;">
          <van-button round block type="info" :disabled="drawInfo.user_get_time" native-type="submit">{{
            drawInfo.user_get_time ? '已填写领奖信息' : '提交' }}</van-button>
        </div>
      </van-form>

      <div class="qrcode-wrap" v-else>
        <img :src="drawInfo.draw_qrcode">
      </div>

    </div>

  </div>
</template>

<script>
import Vue from "vue";
import { Button, Lazyload, Toast } from "vant";

import { Form } from 'vant';
import { Field } from 'vant';

Vue.use(Form);
Vue.use(Field);

import "vant/es/dialog/style";

Vue.use(Lazyload);
Vue.use(Button);
Vue.use(Toast);

import dySdk from '@/douyin_open.js';

export default {
  name: "get-prize",
  data() {
    return {
      openid: '',
      client_key: '',
      drawInfo: null,
      user: {}
    };
  },
  created() {
    document.title = '领取奖品';

    this.openid = localStorage.getItem('openid');

    if (!this.openid) {
      this.initDySdk();
    } else {
      this.getPrizeInfo();
    }
  },

  mounted() {
    // this.loadData();
  },
  methods: {
    getPrizeInfo() {
      Toast.loading({
        message: '加载中...',
        forbidClick: false,
      });

      const draw_id = this.$route.params.id;
      // console.log(draw_id);

      this.$get('gwc/dy/draw_info', { openid: this.openid, draw_id: draw_id }, res => {
        Toast.clear();

        // Toast(JSON.stringify(res));
        if (res.code == 0) {
          this.drawInfo = res.data;
          this.user.name = this.drawInfo.user_nickname;
          this.user.mobile = this.drawInfo.dp_mobile;

        } else {
          Toast.fail(res.message);
        }
      });
    },
    initDySdk() {
      Toast.loading({
        message: '加载中...',
        forbidClick: false,
      });

      this.$get('gwc/dy/config', { url: window.location.href }, res => {
        Toast.clear();

        // console.log(res);
        if (res.code == 0) {
          dySdk.config({
            params: res.data
          });

          this.client_key = res.data.client_key;

          dySdk.ready(() => {
            console.log('ok');
          });
          dySdk.error((res) => {
            console.error(res);
          });
        }
      });

    },
    login() {
      // console.log(dySdk);
      Toast.loading({
        message: '授权登录中...',
        forbidClick: false,
      });

      dySdk.showOpenAuth({
        params: {
          client_key: this.client_key,
          state: '',
          scopes: {
            user_info: 0, // 0: 必选；1: 可选，默认不选中； 2: 可选，默认选中
            mobile_alert: 0,
          },
          response_type: "code",
        },
        success: res => {
          // 成功回调
          console.log(res);
          this.$post('gwc/dy/login', { code: res.ticket }, res => {
            Toast.clear();
            if (res.code == 0) {
              localStorage.setItem('openid', res.data.open_id);
              this.openid = res.data.open_id;

              this.getPrizeInfo();
            }
          });
        },
        error: res => {
          // 失败回调
          console.error(res);
          Toast.clear();
          Toast.fail('登录失败，请重试');
        }
      });
    },
    onSubmit() {
      Toast.loading({
        message: '正在提交...',
        forbidClick: true,
      });

      const params = {
        draw_id: this.$route.params.id,
        openid: this.openid,
        payload: JSON.stringify(this.user)
      }

      // Toast.success(JSON.stringify(params));

      // return;

      this.$post('gwc/dy/contact', params, res => {
        // Toast.clear();
        if (res.code == 0) {
          Toast.success('领取成功!');
          this.drawInfo = res.data;
        } else {
          Toast.fail(res.message);
        }
      })

    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;

  .logo {
    background-color: rgb(74, 131, 252);
    width: 120px;
    height: 120px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 120px auto 20px;
  }

  .name {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    text-align: center;
    font-family: 'PingFang SC';
  }

  .logo-wrap {
    margin-bottom: 120px;
  }

  .login-btn {
    padding: 0 60px;

    .agree {
      font-size: 12px;
      color: #333;
      margin-top: 5px;
      padding: 5px 0;
    }
  }
}

.draw-info {
  padding: 30px 0;

  .ok {
    font-size: 16px;
    color: red;
    font-weight: bold;
    text-align: center;
    padding: 30px 30px;
  }

  .info {
    font-size: 14px;
    color: #999;
    padding: 5px 15px 10px;
    // text-align: center;
  }

  .user {
    text-align: center;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    .name {
      font-size: 14px;
      color: #333;
      font-weight: bold;
      margin-top: 5px;
    }
  }
}

.qrcode-wrap {
  padding: 5px 15px;

  img {
    width: 100%;
    height: auto;
  }
}</style>